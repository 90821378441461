/**
 * Valid EMAIL format
 * lowercase, uppercase, digits, dots, dash before @
 * Not starting by dot
 * valid domain and TLD with more than one dot
*/
const EMAIL_REGEX = /^((?!\.)[\w-_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gim

/**
 * Valid PASSWORD format
 * more than 8 chars
 * almost 1 uppercase
 * almost 1 lowercase
 * almost 1 digit
 * almost 1 special char between: @ $ ! % _ - # * ? &
*/
const PASSWORD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%_\-#*?&])[A-Za-z\d@$!%_\-#*?&]{9,}$/i

export const validateEmailFormat = (val) => {
  return val.match(EMAIL_REGEX)
}

export const validatePasswordFormat = (val) => {
  return val.match(PASSWORD_REGEX)
}
