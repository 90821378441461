<template>
  <div class="d-flex h-100 w-100">
    <div class="w-50 h-100 d-flex flex-column align-items-center justify-content-center fingerpint--option">
      <preview
        :image="imageData"
        :blue="true"
      />
      <!-- Hide Scan fingerprint button for staff login, until develop it -->
      <Button
        v-if=true
        template="blue"
        rounded
        shadow
        uppercase
        class="mt-4"
        :onClick="onLoginWithFingerprint"
        :text="$t('main.scan')"/>
    </div>
    <div class="w-50 h-100 d-flex align-items-center justify-content-center credentials--option position-relative">
      <div class="image-logo position-absolute">
        <img :src="logoImage" />
      </div>
      <form
        id='staffLoginForm'
        @submit.prevent='onSubmit'
        class="w-100 d-flex justify-content-center"
      >
        <div class="d-flex flex-column align-items-end justify-content-center inputs-wrapper">
          <div
            v-if="staffNotRegistered"
            class="d-flex align-items-center justify-content-center w-100 not-registerd-msg mb-4">
            <span class="material-icons-outlined">help_center</span>
            <span class="ms-4 fw-bold">{{ $t('main.staff_not_registered') }}</span>
          </div>
          <InputField
            :label="$t('main.id')"
            class="mb-4"
            name="id"
            :leftIcon="iconUserImage"
            v-model="staffEmail"
            :onChange="validateSaffEmailField"
            :errorMessage="this.staffEmailErrorMessage"
            />
          <InputField
            :label="$t('main.password')"
            class="mb-4"
            :type="showPassword ? 'text' : 'password'"
            :leftIcon="iconPasswordImage"
            :rightIcon="showPassword ? iconPasswordVisibilityOn : iconPasswordVisibilityOff"
            :onChange="validateSaffPasswordField"
            @onClickRightIcon="() => showPassword = !showPassword"
            v-model="staffPassword"
            :errorMessage="this.staffPasswordErrorMessage"
            />
          <Button
            template="blue"
            rounded
            shadow
            uppercase
            class="mt-4"
            :onClick="onSubmit"
            :text="$t('main.enter')"/>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { ACCESS_TOKEN, USER_DATA } from '@/constants/constants'
import { mapActions, mapGetters } from 'vuex'
import { validateEmailFormat, validatePasswordFormat } from '@/composition/validators'
import Button from '@/components/MainButton'
import InputField from '@/components/InputField'
import Preview from '@/components/Preview'
import auth from '@/communication/auth'
import fingerprintReader from '@/composition/fingerprintReader'
// import iconUser from '@/assets/'
import imgFieldId from '@/assets/login-user-icon.svg'
import imgFieldPassword from '@/assets/login-password-icon.svg'
import imgLogoName from '@/assets/heuristik-name.png'
import imgPasswordHidden from '@/assets/login-no-view-password-icon.svg'
import imgPasswordShowing from '@/assets/login-view-password-icon.svg'

export default {
  name: 'IdentifyStaff',
  components: {
    Button,
    InputField,
    Preview
  },
  setup () {
    const { imageData, captureFingerprint, clean, resetFingerprint, stopCapture } = fingerprintReader()

    return {
      imageData,
      captureFingerprint,
      clean,
      resetFingerprint,
      stopCapture
    }
  },
  data () {
    return {
      formErrorsQuantity: 0,
      iconUserImage: imgFieldId,
      iconPasswordImage: imgFieldPassword,
      iconPasswordVisibilityOff: imgPasswordHidden,
      iconPasswordVisibilityOn: imgPasswordShowing,
      logoImage: imgLogoName,
      showPassword: false,
      staffEmail: '',
      staffEmailErrorMessage: '',
      staffNotRegistered: false,
      staffPassword: '',
      staffPasswordErrorMessage: ''
    }
  },
  computed: {
    ...mapGetters([
      'getAllSensors'
    ])
  },
  beforeMount () {
    if (auth.checkAuth()) {
      this.$emit('staffLogged', true)
    } else {
      // this.captureFingerprint()
    }
    // this.captureFingerprint()
  },
  methods: {
    ...mapActions([
      'loginStaff',
      'loginStaffWithFingerPrint',
      'fetchSensors'
    ]),
    onSubmit () {
      // Reset errors and re-validate
      this.staffEmailErrorMessage = ''
      this.staffPasswordErrorMessage = ''
      this.formErrorsQuantity = 0
      this.validateForm()
    },
    validateForm () {
      this.validateSaffEmailField()
      this.validateSaffPasswordField()
      this.onLoginStaff()
    },
    validateSaffEmailField () {
      this.staffEmailErrorMessage = ''
      // Required
      if (this.staffEmail.length > 0) {
        if (!validateEmailFormat(this.staffEmail)) {
          this.staffEmailErrorMessage = this.$t('validations.bad_format', { field: this.$t('main.id') })
          this.formErrorsQuantity++
        }
      } else {
        this.staffEmailErrorMessage = this.$t('validations.is_required')
        this.formErrorsQuantity++
      }
    },
    validateSaffPasswordField () {
      this.staffPasswordErrorMessage = ''
      // Required
      if (this.staffPassword.length > 0) {
        if (!validatePasswordFormat(this.staffPassword)) {
          this.staffPasswordErrorMessage = this.$t('validations.bad_format', { field: this.$t('main.password') })
          this.formErrorsQuantity++
        }
      } else {
        this.staffPasswordErrorMessage = this.$t('validations.is_required')
        this.formErrorsQuantity++
      }
    },
    async onLoginStaff () {
      auth.clearAll()
      if (this.formErrorsQuantity === 0) {
        this.$emit('loading', true)
        try {
          const response = await this.loginStaff({ email: this.staffEmail, password: this.staffPassword })
          if (response) {
            localStorage.setItem(ACCESS_TOKEN, response.token)
            localStorage.setItem(USER_DATA, JSON.stringify(response.userDetails))
            this.$emit('staffLogged', true)
            this.fetchSensors()
          } else {
            this.staffNotRegistered = true
          }
          this.$emit('loading', false)
        } catch (error) {
          this.staffNotRegistered = true
        }
      }
    },
    async onLoginWithFingerprint () {
      this.$emit('loading', true)
      const data = {
        // sensorId: this.getAllSensors[0].id,
        sensorId: '8238150b-786e-4097-8a9f-03412514ffff', // @MOCKED
        fingerprintBase64: this.clean(this.imageData)
      }
      try {
        const response = await this.loginStaffWithFingerPrint(data)
        if (response) {
          localStorage.setItem(ACCESS_TOKEN, response.token)
          localStorage.setItem(USER_DATA, JSON.stringify(response.userDetails))
          this.$emit('staffLogged', true)
          // this.fetchSensors() // 2023-05-25 Temporally deactivated
          this.stopCapture()
        } else {
          this.staffNotRegistered = true
          this.resetFingerprint()
        }
        this.$emit('loading', false)
      } catch (error) {
        this.staffNotRegistered = true
        this.resetFingerprint()
      }
    }
  }
}
</script>

<style lang="scss" scope>
.image-logo {
  top: 5%;
  right: 10%;
  width: 169px;
  height: 27px;
  img {
    width: 100%;
    max-width: 100%;
  }
}
.not-registerd-msg {
  background-color: rgb(255 217 217);
  color: $red-color;
  border-radius: 8px;
  padding: 10px;
}
.fingerpint--option {
  background-color: $background-app-color;
  border-right: 2px solid #dedede;
}
.credentials--option {
  background-color: $white-color;
}
.inputs-wrapper {
  width: 80%;
}
</style>
