<template>
  <div class="d-flex flex-column align-items-start w-100">
    <span class="input-label mb-2 ms-2">
      {{ label }}
    </span>
    <div class="d-flex justify-content-start align-items-center input-wrapper w-100">
      <span
        v-if="leftIcon"
      >
        <img v-if="leftIcon" :src="leftIcon" />
      </span>
      <input
        :class="{'ms-3': leftIcon, 'me-3': rightIcon}"
        class="w-100"
        :type="type"
        :value="modelValue"
        @input="$emit('update:modelValue', $event.target.value)"
        @change.stop="onChangeInput"
        />
      <span
        v-if="rightIcon"
        class="right-icon"
        @click="$emit('onClickRightIcon')"
      >
        <img v-if="rightIcon" :src="rightIcon" />
      </span>
    </div>
    <div class="align-self-end he-ui-font-validation-error">
      {{ errorMessage }}
    </div>
  </div>
</template>

<script>

export default {
  name: 'InputField',
  props: {
    label: {
      type: String,
      required: true
    },
    type: {
      type: String,
      default: 'text'
    },
    leftIcon: {
      type: String,
      default: null
    },
    rightIcon: {
      type: String,
      default: ''
    },
    modelValue: {
      type: String,
      default: '',
      required: true
    },
    onChange: {
      type: Function,
      default: () => {}
    },
    errorMessage: {
      type: String,
      default: ' '
    }
  },
  methods: {
    onChangeInput () {
      console.log('onChange native!')
      if (this.onChange) this.onChange()
    }
  }
}
</script>

<style lang="scss" scoped>
input {
  outline: none;
  border: none;
  height: 48px;
  width: 563px;
  font-size: 22px;
  color: $cement-color;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px $white-color inset !important;
}
input:-webkit-autofill{
    -webkit-text-fill-color: $cement-color !important;
    font-weight: 400;
    font-size: 22px;
}
.input-label,
.input--left-icon,
.input--right-icon {
  color: $cement-color;
  font-weight: 400;
  font-size: 22px;
}

.input-wrapper {
  border: 3px solid $cement-color;
  border-radius: 20px;
  padding: 10px;
}

.right-icon {
  cursor: pointer;
  height: 100%;
  width: 32px;
  display: flex;
  align-items: center;
}

.he-ui-font-validation-error {
  font-size: 18px;
  font-weight: 400;
  color: $red-color;
  min-height: 20px;
}
</style>
